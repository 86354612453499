import { Box, Link, Stack } from '@mui/material'
import logo from 'img/cl_02.png'


const TsunaguLogo = () => {
    return (
        <Stack
            component={Link}
            alignItems="center"
            href="https://tsunagu-pr.com/"
            sx={{
                '&:hover' : {
                    opacity : 0.5,
                },
            }}
        >
            <Box
                component="img"
                height={50}
                src={logo}
            />
        </Stack>
    )
}

export default TsunaguLogo
