import { useQuery } from '@tanstack/react-query'
import { eachMonthOfInterval, format, subMonths } from 'date-fns'
import { API } from 'aws-amplify'
import { apiName } from 'config/api'
import { useCompanyInfo } from 'hooks/company'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getDynamo', {})
    return ret
}

const getVideoHistoryData = async () => {
    const ret = await API.get(apiName, '/items/getDynamoVideoHistory', {})
    return ret
}

const getResearch = async (companyData) => {
    const months = eachMonthOfInterval({ start : new Date('2018-02-01'), end : subMonths(new Date(), 3) })
        .map(m => format(m, 'yyyy-MM'))
    const reqMonths = []
    // TODO: リサーチ一覧画面のデータ量が容量オーバーしている為、暫定対応として取得条件を12か月→10か月に変更
    while (months.length) reqMonths.push(months.splice(0, 10))

    console.log('getResearch start', new Date())

    const ret = await Promise.all(
        reqMonths.map(m => 
            API.post(apiName, '/items/getDynamoResearch', {
                body : {
                    リクエスト_ビジネス_機能 : companyData.リクエスト_ビジネス_機能,
                    months : m,
                }
            })
        )
    )
    const researches = ret.flat(2)
        .map(r => ({
            ...r,
            募集締切日時: new Date(r.募集締切日時),
            募集開始日時_通常用: new Date(r.募集開始日時_通常用),
        }))
        .reduce((o, r) => {
            o[r.リクエストNo] = r
            return o
        }, {})

    console.log('getResearch end', new Date())

    return researches
}

export const useDynamo = () => {
    return useQuery(['dynamo'], getData)
}

export const useDynamoItem = (item) => {
    return useQuery(['dynamo'], getData, {
        select: (data) => {
            return data[item]
        },
    })
}

export const useDynamoVideoHistory = () => {
    return useQuery(['videoHistory'], getVideoHistoryData, {
        select: (data) => data.map(d => {
            const users = Array.from(new Set(d.担当者リスト.map(u => u.企業担当者ID)))

            return {
                ...d,
                担当者リスト: users.map(u => d.担当者リスト
                    .filter(l => l.企業担当者ID === u)
                    .sort((a, b) => new Date(a.視聴日時) - new Date(b.視聴日時))
                    .reduce((o, l) => ({ ...l, 視聴時間: (o.視聴時間 || 0) + l.視聴時間 }), {})
                )
            }
        })
    })
}

export const useDynamoResearch = () => {
    const { data : companyData } = useCompanyInfo()
    return useQuery(['dynamoResearch'], () => getResearch(companyData), {
        enabled: !!companyData,
    })
}
