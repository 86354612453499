import { AppBar, Box, Stack, Toolbar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import NetamotoLogo from './NetamotoLogo'
import MenuButton from './MenuButton'
import MoreButton from './MoreButton'
import FeedbackButton from './FeedbackButton'
import MessageButton from './MessageButton'
import CompanyLogo from './CompanyLogo'
import TsunaguLogo from './TsunaguLogo'
import { useAvailableFeatures } from 'hooks/company'


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

const MenuBar = () => {
    const narrow = useMediaQuery('(max-width:840px)')
    const { data } = useAvailableFeatures()

    return (<>
        <AppBar
            sx={{
                bgcolor : '#fff',
                alignItems : 'center',
                borderBottom : 1,
                borderColor : 'grey.300',
                px : 1,
                boxShadow : '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
        >
            <Toolbar
                sx={{
                    width : "100%",
                    maxWidth : 1212,
                    p : { md : 0 },
                    gap : 1,
                }}
            >
                <MenuButton />
                <NetamotoLogo />
                <TsunaguLogo />
                <Stack
                    direction="row"
                    pl={1.5}
                    gap={2.5}
                >
                    {data?.フィードバック && !narrow &&
                    <FeedbackButton />
                    }
                    <MessageButton />
                </Stack>
                <Box flexGrow={1} />
                <CompanyLogo />
                <MoreButton />
            </Toolbar>
        </AppBar>
        <Offset />
    </>)
}

export default MenuBar
