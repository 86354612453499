import { useQuery } from '@tanstack/react-query'
import { apiName } from 'config/api'
import { API } from 'lib/api'

const getAwsConfig = async () => {
    return await API.get(apiName, "/items/getAwsConfig", {})
}

const getURLs = async () => {
    return await API.get(apiName, "/items/getURLs", {})
}

const getAppConfig = async (app) => {
    return await API.get(apiName, `/items/getAppConfig?app=${app}`, {})
}

export const getKintoneFile = async (fileKey) => {
    const data = await API.post(apiName, "/items/downloadKintoneFile", { body: { fileKey: fileKey }, responseType: 'text' })
    const bin = atob(data)
    const buff = new Uint8Array(bin.length).map((b, i) => bin.charCodeAt(i))
    return new Blob([buff.buffer])
}

export const useS3Config = () => {
    return useQuery(['awsconfig'], getAwsConfig, {
        select: (data) => data.s3
    })
}
export const useGoogleMapUrl = () => {
    return useQuery(['url'], getURLs, {
        select : (data) => data.googlemap
    })
}

export const useManualURL = () => {
    return useQuery(['url'], getURLs, {
        select : (data) => data.manual
    })
}

export const useAppConfig = (app) => {
    return useQuery(['appconfig', app], () => getAppConfig(app))
}

export const useDropDownOptions = (app, field) => {
    return useQuery(['appconfig', app], () => getAppConfig(app), {
        select: (data) => Object.values(data.properties[field].options)
            .sort((a, b) => Number(a.index) - Number(b.index))
            .map(o => o.label)
    })
}

