// import { API } from 'aws-amplify'
import { apiName } from 'config/api'
import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { addMonths, isAfter } from 'date-fns'
import { useDropDownOptions } from 'hooks/variousHooks'


const getData = async () => {
    const ret = await API.get(apiName, '/items/getVideoStreaming', {})
    return ret.map(r => {
        return {
            ...r,
            ライブ配信開始日時: new Date(r.ライブ配信開始日時),
            ライブ配信終了日時: new Date(r.ライブ配信終了日時),
            アーカイブ配信終了日時: new Date(r.アーカイブ配信終了日時),
            表示終了日時: new Date(r.表示終了日時)
        }
    })
}

const now = new Date()

export const useStreamingTop = () => {

    return useQuery(['streaming'], getData
        , {
            select: (data) => data
                .filter(r => isAfter(r.ライブ配信終了日時, new Date()))
                .map(d => {
                    d.newly = isAfter(addMonths(d.ライブ配信開始日時, 1), now)

                    return d
                })
                .sort((a, b) => b.ライブ配信開始日時 - a.ライブ配信開始日時)
                .filter(d => d.経営PR戦略講座.length > 0)
                .slice(0, 6)
        }
    )
}

const tabs = ['基礎', '応用', '実践']

export const useStreamingList = () => {
    const { data: categories } = useDropDownOptions('videoStreaming', 'カテゴリ')

    return useQuery(['streaming'], getData, {
        enabled: !!categories,
        select: (data) => {
            const ret = []
            const list = data
                .filter(r => isAfter(r.ライブ配信終了日時, new Date()))
                .map(d => {
                    d.newly = isAfter(addMonths(d.ライブ配信開始日時, 1), now)

                    return d
                })
                .sort((a, b) => b.ライブ配信開始日時 - a.ライブ配信開始日時)
                .reverse()

            tabs.forEach(t => {
                const td = list.filter(d => d.タブ管理 === t)
                if (td.length) {
                    const tdata = { タブ: t, data: [] }
                    categories.forEach(c => {
                        const cate = td.filter(d => d.カテゴリ === c)
                        if (cate.length) {
                            tdata.data.push({
                                カテゴリ: c,
                                list: cate,
                            })
                        }
                    })
                    ret.push(tdata)
                }
            })
            return ret
        },
    })
}

export const useAllStreaming = () => {
    return useQuery(['streaming'], getData)
}


